import imageInput from '@/mixins/image-input.js';
import {mapState} from "vuex";
import PredictionsPostModel from "../predictions/models/predictions-post.model";
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
export default {
    data() {
        return {
            item: {},
            resultDialog: false,
            activeResultItem: null,
            date: '',
            dtp1: false,
            dtp3: false,
            dtp4: false,
            dtp2: false
        }
    },
    components: {
        quillEditor
    },
    methods: {
        removeItem() {
            this.$store.dispatch("predictions/deleteItem", this.selected);
            this.clearDialog();
        },
        approve() {
            let data = new PredictionsPostModel(this.item);

            let formData = new FormData();
            for (let key in data) {
                if(data[key] || data[key]==0){
                    formData.append(key, data[key]);
                }
            }

            this.$store.dispatch("predictions/updateItem", {id: this.id, data: formData});
        },
        changeFirstTeamLogo(e) {
            this.resfresh = false;
            let formData = new FormData();
            formData.append('Photo', e);
            this.$store.dispatch("getFileUrl", formData).then(res => {
                this.item.firstLogo = res;
            })
        },
        changeSecondTeamLogo(e) {
            this.resfresh = false;
            let formData = new FormData();
            formData.append('Photo', e);
            this.$store.dispatch("getFileUrl", formData).then(res => {
                this.item.secondLogo = res;
            })
        },
        publishItem(e) {
            this.activeResultItem = e;
            this.resultDialog = true;
        },
        approveResult(e) {
            this.$store.dispatch("predictions/approveResult", {
                id: this.activeResultItem,
                result: {
                    isSucceed: e,
                },
            }).then(() => {
                this.activeResultItem = null;
                this.resultDialog = false;
            })
        },
    },
    created() {
        this.$store.dispatch("predictions/getItemById", this.id).then((res) => {
            this.item = res;
        });
    },
    computed: {
        id() {
            return this.$route.params.id;
        },
        ...mapState({
            tournaments: (state) => state.tournaments.items,
            games: (state) => state.games.items,
            categories: state => state.categories.items,
        }),
    },
    mixins: [imageInput],

}
